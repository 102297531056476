import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Contents = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem;
`;

const MapOfJapan = styled.div`
  display: grid;
  grid-template-rows: repeat(15, 70px);
  grid-template-columns: repeat(15, 70px);
  grid-gap: 5px;
`;

const Examples = styled.div``;

const Line1 = styled.div`
  grid-column: 1 / 4;
  grid-row: 6 / 9;
  border-right: solid 2px white;
  border-bottom: solid 2px white;
  border-bottom-right-radius: 30px;
`;

const Line2 = styled.div`
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  border-right: solid 2px white;
  border-bottom: solid 2px white;
  border-bottom-right-radius: 30px;
`;

const Todofuken = styled.div`
  background: ${(props) => {
    if (props.inputColor >= 15) {
      return "tomato";
    } else if (props.inputColor >= 5) {
      return "orange";
    } else if (props.inputColor >= 1) {
      return "lemonchiffon";
    } else {
      return "grey";
    }
  }};
  border-radius: 5px;
`;

const Hokkaido1 = styled(Todofuken)`
  grid-column: 13 / 16;
  grid-row: 1 / 4;
`;

const Hokkaido2 = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 4 / 5;
  margin-top: -50px;
`;

const Aomori = styled(Todofuken)`
  grid-column: 14 / 15;
  grid-row: 4 / 5;
`;

const Iwate = styled(Todofuken)`
  grid-column: 14 / 15;
  grid-row: 5 / 6;
`;

const Miyagi = styled(Todofuken)`
  grid-column: 14 / 15;
  grid-row: 6 / 7;
`;

const Akita = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 5 / 6;
`;

const Yamagata = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 6 / 7;
`;

const Fukushima = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 7 / 8;
`;

const Ibaraki = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 9 / 10;
`;

const Tochigi = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 8 / 9;
`;

const Gunma = styled(Todofuken)`
  grid-column: 12 / 13;
  grid-row: 8 / 9;
`;

const Saitama = styled(Todofuken)`
  grid-column: 12 / 13;
  grid-row: 9 / 10;
`;

const Chiba = styled(Todofuken)`
  grid-column: 13 / 14;
  grid-row: 10 / 11;
`;

const Tokyo = styled(Todofuken)`
  grid-column: 12 / 13;
  grid-row: 10 / 11;
`;

const Kanagawa = styled(Todofuken)`
  grid-column: 12 / 13;
  grid-row: 11 / 12;
`;

const Niigata = styled(Todofuken)`
  grid-column: 12 / 13;
  grid-row: 7 / 8;
`;

const Toyama = styled(Todofuken)`
  grid-column: 11 / 12;
  grid-row: 8 / 9;
`;

const Ishikawa = styled(Todofuken)`
  grid-column: 10 / 11;
  grid-row: 9 / 10;
`;

const Fukui = styled(Todofuken)`
  grid-column: 9 / 10;
  grid-row: 10 / 11;
`;

const Yamanashi = styled(Todofuken)`
  grid-column: 11 / 12;
  grid-row: 10 / 11;
`;

const Nagano = styled(Todofuken)`
  grid-column: 11 / 12;
  grid-row: 9 / 10;
`;

const Gifu = styled(Todofuken)`
  grid-column: 10 / 11;
  grid-row: 10 / 11;
`;

const Shizuoka = styled(Todofuken)`
  grid-column: 11 / 12;
  grid-row: 11 / 12;
`;

const Aichi = styled(Todofuken)`
  grid-column: 10 / 11;
  grid-row: 11 / 12;
`;

const Mie = styled(Todofuken)`
  grid-column: 9 / 10;
  grid-row: 12 / 13;
`;

const Shiga = styled(Todofuken)`
  grid-column: 9 / 10;
  grid-row: 11 / 12;
`;

const Kyoto = styled(Todofuken)`
  grid-column: 8 / 9;
  grid-row: 10 / 11;
`;

const Osaka = styled(Todofuken)`
  grid-column: 7 / 8;
  grid-row: 11 / 12;
`;

const Hyogo = styled(Todofuken)`
  grid-column: 7 / 8;
  grid-row: 10 / 11;
`;

const Nara = styled(Todofuken)`
  grid-column: 8 / 9;
  grid-row: 11 / 12;
`;

const Wakayama = styled(Todofuken)`
  grid-column: 8 / 9;
  grid-row: 12 / 13;
`;

const Tottori = styled(Todofuken)`
  grid-column: 6 / 7;
  grid-row: 10 / 11;
`;

const Shimane = styled(Todofuken)`
  grid-column: 5 / 6;
  grid-row: 10 / 11;
`;

const Okayama = styled(Todofuken)`
  grid-column: 6 / 7;
  grid-row: 11 / 12;
`;

const Hiroshima = styled(Todofuken)`
  grid-column: 5 / 6;
  grid-row: 11 / 12;
`;

const Yamaguchi = styled(Todofuken)`
  grid-column: 4 / 5;
  grid-row: 11 / 12;
`;

const Tokushima = styled(Todofuken)`
  grid-column: 6 / 7;
  grid-row: 13 / 14;
`;

const Kagawa = styled(Todofuken)`
  grid-column: 6 / 7;
  grid-row: 12 / 13;
`;

const Ehime = styled(Todofuken)`
  grid-column: 5 / 6;
  grid-row: 12 / 13;
`;

const Kochi = styled(Todofuken)`
  grid-column: 5 / 6;
  grid-row: 13 / 14;
`;

const Fukuoka = styled(Todofuken)`
  grid-column: 3 / 4;
  grid-row: 12 / 13;
`;

const Saga = styled(Todofuken)`
  grid-column: 2 / 3;
  grid-row: 12 / 13;
`;

const Nagasaki = styled(Todofuken)`
  grid-column: 1 / 2;
  grid-row: 12 / 13;
`;

const Kumamoto = styled(Todofuken)`
  grid-column: 2 / 3;
  grid-row: 13 / 14;
`;

const Oita = styled(Todofuken)`
  grid-column: 3 / 4;
  grid-row: 13 / 14;
`;

const Miyazaki = styled(Todofuken)`
  grid-column: 3 / 4;
  grid-row: 14 / 15;
`;

const Kagoshima = styled(Todofuken)`
  grid-column: 2 / 3;
  grid-row: 14 / 15;
`;

const Okinawa = styled(Todofuken)`
  grid-column: 2 / 3;
  grid-row: 7 / 8;
`;

const Foreign = styled(Todofuken)`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;

const Prefecture = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrefectureName = styled.div`
  padding: 0.2rem;
  text-align: center;
`;

const PrefectureValue = styled.div`
  padding: 0.2rem;
  text-align: center;
`;

const BirthplacePage = ({ data }) => {
  let prefectures = {};

  data.allPrefectureJson.nodes.map((tmp) => {
    prefectures[tmp.name] = tmp.value;
  });

  return (
    <Layout title="Birthplace">
      <SEO pagetitle="Birthplace" pagedesc="棋士の出身地" />
      <ContentWrapper>
        <Contents>
          <MapOfJapan>
            <Line1 />
            <Line2 />
            <Hokkaido1 inputColor={prefectures["北海道"]}>
              <Prefecture>
                <PrefectureName>北海道</PrefectureName>
                <PrefectureValue>{prefectures["北海道"]}</PrefectureValue>
              </Prefecture>
            </Hokkaido1>
            <Hokkaido2 inputColor={prefectures["北海道"]} />
            <Aomori inputColor={prefectures["青森"]}>
              <Prefecture>
                <PrefectureName>青森</PrefectureName>
                <PrefectureValue>{prefectures["青森"]}</PrefectureValue>
              </Prefecture>
            </Aomori>
            <Iwate inputColor={prefectures["岩手"]}>
              <Prefecture>
                <PrefectureName>岩手</PrefectureName>
                <PrefectureValue>{prefectures["岩手"]}</PrefectureValue>
              </Prefecture>
            </Iwate>
            <Miyagi inputColor={prefectures["宮城"]}>
              <Prefecture>
                <PrefectureName>宮城</PrefectureName>
                <PrefectureValue>{prefectures["宮城"]}</PrefectureValue>
              </Prefecture>
            </Miyagi>
            <Akita inputColor={prefectures["秋田"]}>
              <Prefecture>
                <PrefectureName>秋田</PrefectureName>
                <PrefectureValue>{prefectures["秋田"]}</PrefectureValue>
              </Prefecture>
            </Akita>
            <Yamagata inputColor={prefectures["山形"]}>
              <Prefecture>
                <PrefectureName>山形</PrefectureName>
                <PrefectureValue>{prefectures["山形"]}</PrefectureValue>
              </Prefecture>
            </Yamagata>
            <Fukushima inputColor={prefectures["福島"]}>
              <Prefecture>
                <PrefectureName>福島</PrefectureName>
                <PrefectureValue>{prefectures["福島"]}</PrefectureValue>
              </Prefecture>
            </Fukushima>
            <Ibaraki inputColor={prefectures["茨城"]}>
              <Prefecture>
                <PrefectureName>茨城</PrefectureName>
                <PrefectureValue>{prefectures["茨城"]}</PrefectureValue>
              </Prefecture>
            </Ibaraki>
            <Tochigi inputColor={prefectures["栃木"]}>
              <Prefecture>
                <PrefectureName>栃木</PrefectureName>
                <PrefectureValue>{prefectures["栃木"]}</PrefectureValue>
              </Prefecture>
            </Tochigi>
            <Gunma inputColor={prefectures["群馬"]}>
              <Prefecture>
                <PrefectureName>群馬</PrefectureName>
                <PrefectureValue>{prefectures["群馬"]}</PrefectureValue>
              </Prefecture>
            </Gunma>
            <Saitama inputColor={prefectures["埼玉"]}>
              <Prefecture>
                <PrefectureName>埼玉</PrefectureName>
                <PrefectureValue>{prefectures["埼玉"]}</PrefectureValue>
              </Prefecture>
            </Saitama>
            <Chiba inputColor={prefectures["千葉"]}>
              <Prefecture>
                <PrefectureName>千葉</PrefectureName>
                <PrefectureValue>{prefectures["千葉"]}</PrefectureValue>
              </Prefecture>
            </Chiba>
            <Tokyo inputColor={prefectures["東京"]}>
              <Prefecture>
                <PrefectureName>東京</PrefectureName>
                <PrefectureValue>{prefectures["東京"]}</PrefectureValue>
              </Prefecture>
            </Tokyo>
            <Kanagawa inputColor={prefectures["神奈川"]}>
              <Prefecture>
                <PrefectureName>神奈川</PrefectureName>
                <PrefectureValue>{prefectures["神奈川"]}</PrefectureValue>
              </Prefecture>
            </Kanagawa>
            <Niigata inputColor={prefectures["新潟"]}>
              <Prefecture>
                <PrefectureName>新潟</PrefectureName>
                <PrefectureValue>{prefectures["新潟"]}</PrefectureValue>
              </Prefecture>
            </Niigata>
            <Toyama inputColor={prefectures["富山"]}>
              <Prefecture>
                <PrefectureName>富山</PrefectureName>
                <PrefectureValue>{prefectures["富山"]}</PrefectureValue>
              </Prefecture>
            </Toyama>
            <Ishikawa inputColor={prefectures["石川"]}>
              <Prefecture>
                <PrefectureName>石川</PrefectureName>
                <PrefectureValue>{prefectures["石川"]}</PrefectureValue>
              </Prefecture>
            </Ishikawa>
            <Fukui inputColor={prefectures["福井"]}>
              <Prefecture>
                <PrefectureName>福井</PrefectureName>
                <PrefectureValue>{prefectures["福井"]}</PrefectureValue>
              </Prefecture>
            </Fukui>
            <Yamanashi inputColor={prefectures["山梨"]}>
              <Prefecture>
                <PrefectureName>山梨</PrefectureName>
                <PrefectureValue>{prefectures["山梨"]}</PrefectureValue>
              </Prefecture>
            </Yamanashi>
            <Nagano inputColor={prefectures["長野"]}>
              <Prefecture>
                <PrefectureName>長野</PrefectureName>
                <PrefectureValue>{prefectures["長野"]}</PrefectureValue>
              </Prefecture>
            </Nagano>
            <Gifu inputColor={prefectures["岐阜"]}>
              <Prefecture>
                <PrefectureName>岐阜</PrefectureName>
                <PrefectureValue>{prefectures["岐阜"]}</PrefectureValue>
              </Prefecture>
            </Gifu>
            <Shizuoka inputColor={prefectures["静岡"]}>
              <Prefecture>
                <PrefectureName>静岡</PrefectureName>
                <PrefectureValue>{prefectures["静岡"]}</PrefectureValue>
              </Prefecture>
            </Shizuoka>
            <Aichi inputColor={prefectures["愛知"]}>
              <Prefecture>
                <PrefectureName>愛知</PrefectureName>
                <PrefectureValue>{prefectures["愛知"]}</PrefectureValue>
              </Prefecture>
            </Aichi>
            <Mie inputColor={prefectures["三重"]}>
              <Prefecture>
                <PrefectureName>三重</PrefectureName>
                <PrefectureValue>{prefectures["三重"]}</PrefectureValue>
              </Prefecture>
            </Mie>
            <Shiga inputColor={prefectures["滋賀"]}>
              <Prefecture>
                <PrefectureName>滋賀</PrefectureName>
                <PrefectureValue>{prefectures["滋賀"]}</PrefectureValue>
              </Prefecture>
            </Shiga>
            <Kyoto inputColor={prefectures["京都"]}>
              <Prefecture>
                <PrefectureName>京都</PrefectureName>
                <PrefectureValue>{prefectures["京都"]}</PrefectureValue>
              </Prefecture>
            </Kyoto>
            <Osaka inputColor={prefectures["大阪"]}>
              <Prefecture>
                <PrefectureName>大阪</PrefectureName>
                <PrefectureValue>{prefectures["大阪"]}</PrefectureValue>
              </Prefecture>
            </Osaka>
            <Hyogo inputColor={prefectures["兵庫"]}>
              <Prefecture>
                <PrefectureName>兵庫</PrefectureName>
                <PrefectureValue>{prefectures["兵庫"]}</PrefectureValue>
              </Prefecture>
            </Hyogo>
            <Nara inputColor={prefectures["奈良"]}>
              <Prefecture>
                <PrefectureName>奈良</PrefectureName>
                <PrefectureValue>{prefectures["奈良"]}</PrefectureValue>
              </Prefecture>
            </Nara>
            <Wakayama inputColor={prefectures["和歌山"]}>
              <Prefecture>
                <PrefectureName>和歌山</PrefectureName>
                <PrefectureValue>{prefectures["和歌山"]}</PrefectureValue>
              </Prefecture>
            </Wakayama>
            <Tottori inputColor={prefectures["鳥取"]}>
              <Prefecture>
                <PrefectureName>鳥取</PrefectureName>
                <PrefectureValue>{prefectures["鳥取"]}</PrefectureValue>
              </Prefecture>
            </Tottori>
            <Shimane inputColor={prefectures["島根"]}>
              <Prefecture>
                <PrefectureName>島根</PrefectureName>
                <PrefectureValue>{prefectures["島根"]}</PrefectureValue>
              </Prefecture>
            </Shimane>
            <Okayama inputColor={prefectures["岡山"]}>
              <Prefecture>
                <PrefectureName>岡山</PrefectureName>
                <PrefectureValue>{prefectures["岡山"]}</PrefectureValue>
              </Prefecture>
            </Okayama>
            <Hiroshima inputColor={prefectures["広島"]}>
              <Prefecture>
                <PrefectureName>広島</PrefectureName>
                <PrefectureValue>{prefectures["広島"]}</PrefectureValue>
              </Prefecture>
            </Hiroshima>
            <Yamaguchi inputColor={prefectures["山口"]}>
              <Prefecture>
                <PrefectureName>山口</PrefectureName>
                <PrefectureValue>{prefectures["山口"]}</PrefectureValue>
              </Prefecture>
            </Yamaguchi>
            <Tokushima inputColor={prefectures["徳島"]}>
              <Prefecture>
                <PrefectureName>徳島</PrefectureName>
                <PrefectureValue>{prefectures["徳島"]}</PrefectureValue>
              </Prefecture>
            </Tokushima>
            <Kagawa inputColor={prefectures["香川"]}>
              <Prefecture>
                <PrefectureName>香川</PrefectureName>
                <PrefectureValue>{prefectures["香川"]}</PrefectureValue>
              </Prefecture>
            </Kagawa>
            <Ehime inputColor={prefectures["愛媛"]}>
              <Prefecture>
                <PrefectureName>愛媛</PrefectureName>
                <PrefectureValue>{prefectures["愛媛"]}</PrefectureValue>
              </Prefecture>
            </Ehime>
            <Kochi inputColor={prefectures["高知"]}>
              <Prefecture>
                <PrefectureName>高知</PrefectureName>
                <PrefectureValue>{prefectures["高知"]}</PrefectureValue>
              </Prefecture>
            </Kochi>
            <Fukuoka inputColor={prefectures["福岡"]}>
              <Prefecture>
                <PrefectureName>福岡</PrefectureName>
                <PrefectureValue>{prefectures["福岡"]}</PrefectureValue>
              </Prefecture>
            </Fukuoka>
            <Saga inputColor={prefectures["佐賀"]}>
              <Prefecture>
                <PrefectureName>佐賀</PrefectureName>
                <PrefectureValue>{prefectures["佐賀"]}</PrefectureValue>
              </Prefecture>
            </Saga>
            <Nagasaki inputColor={prefectures["長崎"]}>
              <Prefecture>
                <PrefectureName>長崎</PrefectureName>
                <PrefectureValue>{prefectures["長崎"]}</PrefectureValue>
              </Prefecture>
            </Nagasaki>
            <Kumamoto inputColor={prefectures["熊本"]}>
              <Prefecture>
                <PrefectureName>熊本</PrefectureName>
                <PrefectureValue>{prefectures["熊本"]}</PrefectureValue>
              </Prefecture>
            </Kumamoto>
            <Oita inputColor={prefectures["大分"]}>
              <Prefecture>
                <PrefectureName>大分</PrefectureName>
                <PrefectureValue>{prefectures["大分"]}</PrefectureValue>
              </Prefecture>
            </Oita>
            <Miyazaki inputColor={prefectures["宮崎"]}>
              <Prefecture>
                <PrefectureName>宮崎</PrefectureName>
                <PrefectureValue>{prefectures["宮崎"]}</PrefectureValue>
              </Prefecture>
            </Miyazaki>
            <Kagoshima inputColor={prefectures["鹿児島"]}>
              <Prefecture>
                <PrefectureName>鹿児島</PrefectureName>
                <PrefectureValue>{prefectures["鹿児島"]}</PrefectureValue>
              </Prefecture>
            </Kagoshima>
            <Okinawa inputColor={prefectures["沖縄"]}>
              <Prefecture>
                <PrefectureName>沖縄</PrefectureName>
                <PrefectureValue>{prefectures["沖縄"]}</PrefectureValue>
              </Prefecture>
            </Okinawa>
            <Foreign inputColor={prefectures["海外"]}>
              <Prefecture>
                <PrefectureName>海外</PrefectureName>
                <PrefectureValue>{prefectures["海外"]}</PrefectureValue>
              </Prefecture>
            </Foreign>
          </MapOfJapan>
        </Contents>
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allPrefectureJson {
      nodes {
        name
        value
      }
    }
  }
`;

export default BirthplacePage;
